import { ref, readonly, watchEffect, Ref, DeepReadonly } from "@vue/composition-api";

type AsyncComputedResult<T> = {
  result: DeepReadonly<Ref<T>>;
  loading: DeepReadonly<Ref<boolean>>;
};

export default function useResult<T>(
  callback: () => T | Promise<T>,
  defaultValue?: T
): AsyncComputedResult<T> {
  let counter = 0;
  const current = ref(defaultValue) as Ref<T>;
  const loading = ref<boolean>(false);

  watchEffect(async (onInvalidate) => {
    counter++;
    const counterAtBeginning = counter;

    try {
      Promise.resolve().then(() => {
        loading.value = true;
      });

      const result = await callback();

      if (counterAtBeginning === counter) {
        current.value = result;
      }
    } finally {
      loading.value = false;
    }
  });

  return {
    result: readonly(current),
    loading: readonly(loading),
  };
}
