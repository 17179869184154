import { sleep } from './common';

export async function retryPromiseWithDelay<T>(callback: () => Promise<T>, tries: number, delayTime: number): Promise<T> {
  try {
    console.log('attempting');
    const res = await callback();
    return res;
  } catch (e: any) {
    if (tries === 1) {
      throw new Error(e.message);
    }
    console.log('retrying', tries, 'time');
    // wait for delayTime amount of time before calling this method again
    await sleep(delayTime);
    return retryPromiseWithDelay(callback, tries - 1, delayTime);
  }
}
