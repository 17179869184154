import {
  BigNumber, utils, providers, Overrides, Contract, PayableOverrides,
} from 'ethers';
import { TransactionResponse } from '@ethersproject/abstract-provider';

import { Erc20__factory } from '@/generated/contracts';
import { sleep } from '@/utils/common';
import { retryPromiseWithDelay } from '../retryPromise';

const BUSD_CURRENCY_ADDRESS = process.env.VUE_APP_BSC_BUSD_ERC20_CONTRACT_ADDRESS!;

// https://eips.ethereum.org/EIPS/eip-3085
interface AddEthereumChainParameter {
    chainId: string;
    blockExplorerUrls?: string[];
    chainName: string;
    iconUrls?: string[];
    nativeCurrency?: {
        name: string;
        symbol: string;
        decimals: number;
    };
    rpcUrls?: string[];
}

export interface RpcError extends Error {
    code: number;
    message: string;
    data?: unknown;
}

export const numberToHex = (val: number): string => {
  return `0x${val.toString(16)}`;
};

export const isPossibleRpcError = (val: any): val is RpcError => {
  return typeof val.code === 'number';
};

export function getMessageFromEthersError(e: any): { message: string; shouldRetry: boolean; contractException?: boolean } {
  console.log(JSON.stringify(e));

  if (e.code === 4001) {
    return { message: 'Transaction canceled by user', shouldRetry: false };
  } if (e.code === 'CALL_EXCEPTION') {
    return { message: 'Something went wrong', shouldRetry: true };
  } if (e.code === 'UNPREDICTABLE_GAS_LIMIT') {
    if (e.error?.data?.message) {
      return { message: e.error.data.message, shouldRetry: true, contractException: true };
    } if (e.error?.message) {
      return { message: e.error.message, shouldRetry: true, contractException: true };
    } if (e.message) {
      return { message: e.message, shouldRetry: true, contractException: true };
    }
  } else if (e.code === 'REPLACEMENT_UNDERPRICED') {
    return { message: 'Replacement transaction was underpriced. Please try again', shouldRetry: false };
  } else if (e.code === 'TRANSACTION_REPLACED') {
    return { message: 'Transaction was replaced. Please try again', shouldRetry: false };
  }
  if (e?.data?.message) {
    return { message: (e as { data: { message: string } })?.data?.message || 'Something went wrong', shouldRetry: true };
  }
  return { message: (e as { message: string })?.message || 'Something went wrong', shouldRetry: true };
}

export const personalSign = async (blockchain: providers.Web3Provider, message: string): Promise<string> => {
  try {
    const sign = await blockchain.getSigner().signMessage(message);
    return sign;
  } catch (err) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (err && typeof (err as any).code && (err as any).code === 4001) {
      throw new Error('Signature Failed: The user denied signing the transaction.');
    }

    throw err;
  }
};

export async function busdBalanceOf(
  blockchain: providers.Web3Provider,
  address: string,
) {
  const currencyContract = Erc20__factory.connect(BUSD_CURRENCY_ADDRESS, blockchain.getSigner());

  return await currencyContract.balanceOf(address);
}

export async function busdApprove(
  blockchain: providers.Web3Provider,
  amount: BigNumber | string,
  receiver: string,
) {
  const currencyContract = Erc20__factory.connect(BUSD_CURRENCY_ADDRESS, blockchain.getSigner());

  const errorMessage = await retryPromiseWithDelay<string | undefined>(
    async () => {
      try {
        await sleep(100);
        const result: TransactionResponse = await currencyContract.approve(receiver, amount);
        console.log(result);

        await sleep(100);
        await result.wait();
      } catch (e) {
        const { shouldRetry, message } = getMessageFromEthersError(e);

        if (shouldRetry) {
          throw new Error(message);
        } else {
          return message;
        }
      }
    },
    3,
    1000,
  );

  if (errorMessage) {
    throw new Error(errorMessage);
  }

  await retryPromiseWithDelay<string | void | undefined>(
    async () => {
      while (true) {
        const address = await blockchain.getSigner().getAddress();
        console.log('Waiting for 3 secs');

        await sleep(3000);
        const allowance = await currencyContract.allowance(address, receiver);
        console.log('Allowance call executed');

        if (allowance.gte(amount)) break;
      }
    },
    3,
    1000,
  );
}

export async function getApprovedBusdAmount(
  blockchain: providers.Web3Provider,
  address: string,
  receiver: string,
) {
  const currencyContract = Erc20__factory.connect(BUSD_CURRENCY_ADDRESS, blockchain.getSigner());

  return await currencyContract.allowance(address, receiver);
}

export const getMetamaskEthereum = (): any => {
  if (typeof window !== 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (window as any).ethereum;
  }

  return undefined;
};

const CUSTOM_RPC_MAP: Record<number, AddEthereumChainParameter> = {
  97: {
    chainId: numberToHex(97),
    blockExplorerUrls: ['https://explorer.binance.org/smart-testnet/'],
    chainName: 'BSC Testnet',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
  },

  56: {
    chainId: numberToHex(56),
    blockExplorerUrls: ['https://bscscan.com/'],
    chainName: 'BSC Chain',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org/'],
  },
};

export const switchChainIdByMetamask = async (chainId: number): Promise<void> => {
  const ethereum = getMetamaskEthereum();
  const chainIdHex = numberToHex(chainId);

  if (!ethereum) {
    return;
  }

  try {
    await ethereum.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: chainIdHex }],
    });
  } catch (switchError) {
    if (isPossibleRpcError(switchError)) {
      // If the chain has not been added to MetaMask
      if (switchError.code === 4902) {
        try {
          const addChainParam = CUSTOM_RPC_MAP[chainId];

          await ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [addChainParam],
          });
        } catch (e) {
          throw new Error('Please switch your network to BSC Chain in your connected wallet.');
        }
        return;
      }
    }

    throw switchError;
  }
};
