var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fog"},[_c('div',{class:{
      'fog__block': true,
      'bottom': true,
      'left': true,
      'hidden': _vm.fogIsHidden
    }},_vm._l((_vm.fogUnitsPerBlock),function(i){return _c('div',{key:i,staticClass:"fog__item"},[_c('div',{staticClass:"fog__graphic"})])}),0),_c('div',{class:{
      'fog__block': true,
      'bottom': true,
      'right': true,
      'hidden': _vm.fogIsHidden
    }},_vm._l((_vm.fogUnitsPerBlock),function(i){return _c('div',{key:i,staticClass:"fog__item"},[_c('div',{staticClass:"fog__graphic"})])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }